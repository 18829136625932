
import Vue from "vue";
import { Component } from "vue-property-decorator";
import news, { News as NewsType } from "@/store/news";

@Component
export default class News extends Vue {
  name = "News";

  get newses(): NewsType[] {
    return this.$i18n.locale === "zh" ? news.zh : news.en;
  }

  href(news: NewsType): string | undefined {
    if (news.md) return `/news/${news.title}`;
    else if (news.href) return news.href;
  }
}
