
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

type ContentType = string | typeof import("*.md");

@Component
export default class Markdown extends Vue {
  name = "MarkDown";
  @Prop() title?: string;
  @Prop() subtitle?: string;
  @Prop() content?:
    | ContentType
    | Promise<ContentType>
    | (() => ContentType | Promise<ContentType>);
  @Prop() content_en?:
    | ContentType
    | Promise<ContentType>
    | (() => ContentType | Promise<ContentType>);
  @Prop({type: String, default: "zh"}) lang!: "zh" | "en";
  htmlBuffer: string | null = null;
  cur_lang = "zh";

  get html(): string {
    const setHtmlBuffer = (r: ContentType) => {
      //   console.log(r);
      if (typeof r === "string") this.htmlBuffer = r;
      else this.htmlBuffer = r.default;
    };
    if (this.htmlBuffer === null || this.lang !== this.cur_lang) {
      let content = this.lang === "zh" ? this.content : this.content_en;
      if (typeof content === "function") content = content();
      if (content instanceof Promise) {
        this.htmlBuffer = "加载中";
        content.then(
          (r) => setHtmlBuffer(r),
          (err) => (this.htmlBuffer = `加载失败，请刷新重试！(${err})`)
        );
      } else {
        setHtmlBuffer(content as ContentType);
      }
      this.cur_lang = this.lang;
    }
    return this.htmlBuffer as string;
  }

  get isLoading(): boolean {
    return this.htmlBuffer === "加载中";
  }
}
