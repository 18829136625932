
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { News as NewsType } from "@/store/news";
import S404 from "@/views/404.vue";
import Markdown from "@/components/Markdown.vue";
import news from "@/store/news";

@Component({
  components: { Markdown, S404 },
})
export default class NewsDetail extends Vue {
  name = "NewsDetail";

  get news(): NewsType | undefined {
    const n = this.$i18n.locale === "en" ? news.en : news.zh;
    return n.find((v) => v.title === this.$route.params.name);
  }
}
